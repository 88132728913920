import React, { useState } from "react";
import { motion } from "framer-motion";
import Layout from "../../../components/Layout/layout";
import SEO from "../../../components/seo";
import Heading from "../../../components-v2/Base/HeadingBuilder";
import Image from "../../../components/ImageQuerys/RandomImages";
import GlobalImage from "../../../components/image";
import LargeFeatures from "../../../components/LargeFeatures/LargeFeatures";
import {
  hero,
  howItWorks,
  steps,
  largeFeatures,
  quotesSlider,
  productLinks,
  footer,
  quote,
} from "../../../data/lp/dolce-best-practices";
import heroBg from "../../../images/svg/vector-18.svg";
import heroBgLeft from "../../../images/svg/vector-16.svg";
import quotes from "../../../images/svg/quote.svg";
import AppetizeStyles from "../../../styles/lp-styles/appetize-lp-styles";
import ellipse from "../../../images/hero-images/ellipse-2.png"

import "../../../css/products-qr.css";


const ReserveBestPractices = () => {
  const [slideIndex, setSlideIndex] = useState(0);

  return (
    <Layout
      transparentHeader
      isLanding
      useNewLPHeader
      className="non-fixed-width qr-page"
      phoneNumber="+18882246506"
      phoneText="(888) 224-6506"
    >
      <SEO
        title="Labor Management Best Practices | Dolce by SpotOn"
        description="Learn how Dolce by SpotOn enables you to easily manage labor, creating a better work experience for you and your employees."
        robotsData="noindex, nofollow"
      />

      <section className="pt-40 bg-black w-full relative">
        <img
          alt="background"
          src={heroBgLeft}
          className="absolute hidden xl:block left-0 top-24 opacity-0 lg:opacity-100"
        />
        <img
          alt="background"
          src={heroBg}
          className="absolute -right-48 md:right-0 bottom-6"
        />
        <div
          className={`grid grid-cols-1 md:grid-cols-2 relative 
         px-4 md:px-10 lg:px-20 xl:px-40 max-w-1440 mx-auto 
         gap-x-10 2xl:gap-x-48 gap-y-16 
         `}
        >
          <div className="flex flex-col justify-center w-full">
            <Heading
              className="text-white"
              level={1}
              injectionType={1}
              withBlueSymbol
            >
              {hero.title}
            </Heading>

            <div className="text-black-500 text-p-large">
              {hero.description}
            </div>
          </div>

          <div className="w-full flex flex-col justify-center">
            <Image className="w-full h-auto" imageName={hero.imageName} />
          </div>
        </div>

        <div
          className="relative bg-black w-full py-7 mt-20 lg:mt-40"
          style={{ borderTop: "1px solid #fff" }}
        >
          {hero.footer.map((item, idx) => (
            <span
              key={(idx + 1).toString()}
              className="text-white text-[14px] px-5 md:px-10 font-thin"
            >
              {item}
            </span>
          ))}
        </div>
      </section>

      <section
        className={`mt-28
        grid grid-cols-1 lg:grid-cols-2 gap-y-6 gap-x-10
        px-4 md:px-10 lg:px-20 xl:px-40 max-w-1440 mx-auto
      `}
      >
        <div>
          <Heading
            level={2}
            injectionType={2}
            className="lg:max-w-[370px]"
            withBlueSymbol
            symbol="?"
          >
            {howItWorks.title}
          </Heading>
        </div>

        <div>
          {howItWorks.descriptions.map((text, idx) => (
            <p key={idx.toString()} className="text-black-200 text-p">
              {text}
            </p>
          ))}
        </div>
      </section>

      <section
        className={`mt-8 md:mt-24 w-full
        grid grid-cols-1 md:grid-cols-3 gap-y-6 gap-x-10
        px-4 md:px-10 lg:px-20 xl:px-40 max-w-1440 mx-auto
        steps-section
      `}
      >
        <div className="md:min-h-[320px] pl-6 md:pl-10 qr-step-1 py-4 md:py-0 step step-1">
          <div className="text-h1 font-bold text-black">{steps[0].step}</div>
          <div className="text-p text-black-200 max-w-[248px]">
            {steps[0].title}
          </div>
        </div>

        <div className="md:min-h-[320px] pl-6 md:pl-10 qr-step-2 py-4 md:pt-28 step step-2">
          <div className="text-h1 font-bold text-black">{steps[1].step}</div>
          <div className="text-p text-black-200 max-w-[248px]">
            {steps[1].title}
          </div>
        </div>

        <div className="md:min-h-[320px] pl-6 md:pl-10 qr-step-3 py-4 md:py-0 step step-3">
          <div className="text-h1 font-bold text-black">{steps[2].step}</div>
          <div className="text-p text-black-200 max-w-[248px]">
            {steps[2].title}
          </div>
        </div>
      </section>

      <section className="mt-10 lg:mt-28 max-w-[1122px] w-full mx-auto]">
        <GlobalImage
          imageName="dolce-second-hero.png"
          className="w-full min-h-[200px]"
        />
      </section>

      <LargeFeatures className="mt-24 lg:mt-48" sectionData={largeFeatures} />

      {/* <section className={`
        relative
        flex flex-col items-center justify-center
        mx-auto max-w-[880px]
        text-[16px] text-black-300
        mt-20 mb-40 px-4 md:px-10 
      `}>
        <img src={ellipse} alt="background" className="absolute left-0 top-0 right-0 bottom-0 m-auto" />
        <img src={quotes} alt="quotes" />
        <p className="text-center mt-10">{quote.text}</p>
        <Image className="w-[80px] mt-10" imageName={quote.logo} />
        <span className="text-[18px] font-bold text-[#090D12]">{quote.personName}</span>
        <span className="text-[14px] text-black-300">{quote.personTitle}</span>
      </section> */}

      <AppetizeStyles />
    </Layout>
  );
};

export default ReserveBestPractices;
