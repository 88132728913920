export const hero = {
    title: "Labor management best practices",
    description:
      "Everything you need to know about Dolce by SpotOn to manage and retain your staff.",
    imageName: "dolce-hero.png",
    footer: [
      "Dolce",
      "Restaurant",
      "Dolce",
      "Restaurant",
      "Dolce",
      "Restaurant",
      "Dolce",
      "Restaurant",
      "Dolce",
      "Restaurant",
      "Dolce",
      "Restaurant",
      "Dolce",
      "Restaurant",
      "Dolce",
      "Restaurant",
      "Dolce",
      "Restaurant",
      "Dolce",
      "Restaurant",
      "Dolce",
      "Restaurant",
      "Dolce",
      "Restaurant",
      "Dolce",
      "Restaurant",
    ],
  };
  
  export const howItWorks = {
    title: `Why Dolce by SpotOn`,
    descriptions: [
      `Other than pay, the top reasons hourly employees quit their jobs are lack of control over their schedule (59%) and poor communications with their employers (39%.)`,
      `Dolce by SpotOn can help you with both. With labor management software that works seamlessly with the SpotOn Restaurant POS, you can automate cumbersome processes and streamline the communication between you and your staff—making your life easier and your employees' lives happier.`,
      `Dolce by SpotOn makes everyone's work life better by empowering you to:`,
    ],
  };
  
  export const steps = [
    {
      step: 1,
      title: "Create staff schedules while controlling labor costs with sales forecasting",
    },
    {
      step: 2,
      title: "Automatically distribute tips to match your tipping rules and export them to payroll",
    },
    {
      step: 3,
      title: "Seamlessly integrate labor management with the SpotOn Restaurant POS",
    },
  ];
  
  export const largeFeatures = {
    sectionTitle: "Three best practices for success with Dolce by SpotOn",
    featureBlocks: [
      {
        blockTitle: "Make scheduling a breeze",
        blockImg: "dolce-large-1.png",
        blockSubTitle:
          "Say goodbye to making schedules in Excel and communicating with your staff about changes through text. With Dolce by SpotOn, everyone can access and manage their work schedules from a single cloud-based platform.",
        forceReverse: false,
        blockList: [
          `Create schedules effortlessly in minutes with our drag-and-drop templates`,
          `Control your labor costs by making data-driven schedules from imported sales data`,
          `Let staff request shift trades, PTO, and sick time in our app, keeping everything in one place`,
        ],
        footer: `<a href="https://www.360training.com/blog/tips-and-tricks-scheduling-restaurant-employees" class="underline hover:underline" target="_blank">Users claim good software can cut 3 – 4 hours of manual scheduling into 10-15 minutes.</a>`,
      },
      {
        blockTitle: "Do payroll in minutes",
        blockImg: "dolce-large-2.png",
        blockSubTitle:
          "Payroll can be the biggest headache of the week. With Dolce by SpotOn, you can simplify the entire payroll process with automatic tip distribution and direct integration with your payroll provider.",
        blockList: [
          `Automate your tip distribution with even the most complex of rules and an unlimited number of locations`,
          `Integrate your payroll provider with Dolce to seamlessly transfer the data you need to do payroll`,
          `Build trust between you and your employees and remain compliant by ensuring that the information on payroll is accurate and up-to-date`,
          `Review and easily update clock-ins and break times with a 2-way sync to your SpotOn POS`,
        ],
        forceReverse: false,
      },
      {
        blockTitle: "Empower staff to manage their work life",
        blockImg: "dolce-large-3.png",
        blockSubTitle:
          "Give staff the ability to view and make changes to their schedule, see tips made, and receive communications from one app. With Dolce by SpotOn, your staff can:",
        blockList: [
          `See all their schedules for the week (or duration that you choose to set)`,
          `Quickly request to swap or pick up available shifts`,
          `Easily request PTO and sick time, with the ability for you to approve or deny`,
          `Gain visibility into tips made for the night as soon as tips are distributed`,
          `Receive direct or mass messages in the app, keeping communications in one place`,
        ],
        forceReverse: false,
      },
      {
        blockTitle: "Bring your labor management and point-of-sale together",
        blockImg: "dolce-large-4.png",
        blockSubTitle:
          "While Dolce by SpotOn can work independently, it integrates perfectly with the SpotOn Restaurant POS, making your job simpler with one seamless solution.",
        blockList: [
          `Make employees' lives easier with clock-ins from one place—the POS`,
          `Always have the most up-to-date information with a two-way sync between Dolce by SpotOn and the SpotOn POS`,
          `Eliminate confusion by seeing all changes—time punches, wages, and roles—in both the labor-management tool and the POS`,
          `Stay compliant with local labor laws by ensuring all breaks and clock-ins are accurate and in line with your city's code`,
        ],
        forceReverse: false,
      },
    ],
  };
  
  export const quote = {
    text: `“Dolce by SpotOn provides key metrics, like forecasted labor percentage. In the past, you would take a pen and pencil to figure it out. Now all you have to do is make a schedule and metrics populate on a day-by-day basis. It saves nearly two and a half hours most work days.”`,
    logo: "bungalow-kitchen-logo.png",
    personName: "Lino Suazo",
    personTitle: "General Manager, The Bungalow Kitchen",
  };